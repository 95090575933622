import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, CssBaseline, TextField, Link, Grid, Typography, makeStyles, Container, Paper, Box } from "@material-ui/core";
import { versionSystem } from "../../../package.json";
import { i18n } from "../../translate/i18n";
import { nomeEmpresa } from "../../../package.json";
import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/logo.png";
import backgroundImage from "../../assets/img.png";


const Copyright = () => {
	return (
		<Typography variant="body2" color="primary" align="center">
			{"Copyright "}
 			<Link color="primary" href="#">
 				{ nomeEmpresa } - v { versionSystem }
 			</Link>{" "}
 			{new Date().getFullYear()}
 			{"."}
 		</Typography>
 	);
 };

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 93, 0, 0.1)", // Fundo laranja
  },
  box: {
    width: "100%", // Ajuste para 100% para responsividade
    maxWidth: 900, // Máximo de 900px
    height: "auto", // Ajuste para auto para melhorar a responsividade
    display: "flex",
    flexDirection: "column", // Ajuste para coluna em telas menores
    borderRadius: 16,
    boxShadow: "10px 16px 40px rgba(0, 0, 0, 0.3)",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      // Tamanho médio para cima
      flexDirection: "row", // Em telas maiores, usa row
      height: 500, // Altura fixa para telas grandes
    },
  },
  imageSide: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      // Tamanho pequeno para baixo
      display: "none", // Esconde a imagem em telas pequenas
    },
  },
  formSide: {
    flex: 1,
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    padding: ".5rem",
  },
}));

const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ email: "", password: "" });
  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.box}>
        <div className={classes.imageSide} />
        <div className={classes.formSide}>
          <center>
            <img style={{ margin: "0 auto", width: "70%" }} src={logo} alt="Logologin" />
          </center>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
		  <TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label={i18n.t("login.form.email")}
						name="email"
						value={user.email}
						onChange={handleChangeInput}
						autoComplete="email"
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label={i18n.t("login.form.password")}
						type="password"
						id="password"
						value={user.password}
						onChange={handleChangeInput}
						autoComplete="current-password"
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						{i18n.t("login.buttons.submit")}
					</Button>
					<Grid container>
					<Grid item xs>
						<Typography component='p' variant='body1' style={{ display: 'inline' }}>
						Ainda não tem uma conta?
						</Typography>
						<Link href='#' variant='body1' component={RouterLink} to='/signup' style={{ marginLeft: 4 }}>
						Inscreva-se
						</Link>
					</Grid>
					</Grid>
					
				</form>
			<Box mt={8}><Copyright /></Box>
			</div>

      </Paper>
    </div>
  );
};

export default Login;
