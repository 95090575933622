import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Typography, Grid, Card, CardContent, Button, TextField, InputAdornment, Box, Link, IconButton } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import moment from 'moment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import usePlans from '../../hooks/usePlans';
import { openApi } from '../../services/api';
import toastError from '../../errors/toastError';
import logo from '../../assets/logo.png';
import { i18n } from '../../translate/i18n';
import { PiSparkle } from 'react-icons/pi';
import { IoCheckmark } from 'react-icons/io5';

const useStyles = makeStyles((theme) => ({
  backgrounContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  plansCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    color: '#FF5D00',
    textAlign: 'center',
    fontSize: 36,
    fontWeight: 700,
    margin: 0
  },
  subtitle: {
    color: '#FF5D00',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 400,
    marginBottom: theme.spacing(4),
    width: '70%',
  },
  planCardOutline: {
    borderRadius: '13.477px',
    border: '0.842px solid #EF6C00',
    background: '#FBFCFD',
    position: 'relative',
  },
  planCardOutlineMiddle: {
    background: '#FF5D00',
  },
  sparkleIcon: {
    borderRadius: '6.738px',
    background: '#FF5D00',
    color: '#fff',
    padding: 5,
    fontSize: 26,
    marginRight: 5,
  },
  sparkleIconMiddle: {
    background: '#FFEFE6',
    color: '#FF5D00',
    borderRadius: '6.738px',
    padding: 5,
    fontSize: 26,
    marginRight: 5,
  },

  planTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#FF5D00',
  },
  planTitleMiddle: {
    color: '#FFEFE6',
  },
  planHead: {
    color: '#0D203D',
    fontSize: '13.477px',
    fontWeight: '400',
    marginBottom: 0,
  },

  planPrice: {
    color: '#0D203D',
    fontSize: '26.954px',
    fontWeight: 600,
    marginTop: 0,
    marginBottom: '1.5rem',
  },
  divFeature: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
  },
  checkIcon: {
    fontSize: 26,
    width: '10%',
  },
  planFeature: {
    fontSize: 14,
    marginBottom: theme.spacing(0.5),
    fontWeight: 400,
    width: '80%',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  planCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  planButton: {
    backgroundColor: '#FF5D00',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#D97800',
    },
    width: '90%',
    marginTop: '-1rem',
    margin: 'auto',

    borderRadius: 8,
    background: ' #FF5D00',
    boxShadow: '0px 0px 20.2px 0px #EF6C00',
    height: 43,
  },
  planButtonMiddle: {
    border: '2px solid #FFB48A',
    background: 'rgba(255, 239, 230, 0.80)',
    boxShadow: ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    backdropFilter: 'blur(1.7000000476837158px)',
    color: '#B54200',
    '&:hover': {
      backgroundColor: 'rgba(255, 239, 230, 0.90)',
    },
  },
  container: {
    backgroundColor: '#FCEAE0',
    borderRadius: 10,
    padding: theme.spacing(4),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginTop: theme.spacing(4),
  },
  subtitleForm: {
    color: '#333333',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#FFFFFF',
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    marginBottom: theme.spacing(2),
  },
  inputAdornment: {
    color: '#999999',
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  password: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const { list: listPlans } = usePlans();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    async function fetchPlans() {
      const plansList = await listPlans();
      setPlans(plansList);
    }
    fetchPlans();
  }, [listPlans]);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const initialState = { name: '', email: '', phone: '', password: '', confirmPassword: '', planId: '' };
  const [user] = useState(initialState);
  const dueDate = moment().add(3, 'day').format();

  const handleSignUp = async (values) => {
    Object.assign(values, { recurrence: 'MENSAL' });
    Object.assign(values, { dueDate: dueDate });
    Object.assign(values, { status: 't' });
    Object.assign(values, { campaignsEnabled: true });
    try {
      await openApi.post('/companies/cadastro', values);
      toast.success(i18n.t('signup.toasts.success'));
      history.push('/login');
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Container className={classes.backgrounContainer} maxWidth='lg'>
      {!selectedPlan ? (
        <>
          <h1 className={classes.title}>Escolha o Seu Plano</h1>
          <h2 className={classes.subtitle}>Chegou a hora de você gerenciar e organizar o whatsapp da sua empresa com os melhores valores do mercado</h2>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={4}>
              <div className={classes.plansCardContainer}>
                <CardContent className={classes.planCardOutline}>
                  <Typography className={classes.planTitle}>
                    <PiSparkle className={classes.sparkleIcon} />
                    Plano Pro
                  </Typography>
                  <Typography className={classes.planHead}>Para Empresas:</Typography>
                  <Typography className={classes.planPrice}>R$ 99,99</Typography>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Acesso completo à nossa plataforma de multi atendimento</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Ferramentas de mensuração e análise</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Suporte dedicado para sua equipe</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Implementação rápida e fácil</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>10 atendentes</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>10 WhatsApp</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>10 Filas</Typography>
                  </div>
                </CardContent>
                <Button size='small' className={classes.planButton} onClick={() => handleSelectPlan({ id: 8, name: 'Plano Pro', value: 99.99 })}>
                  Começar agora
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.plansCardContainer}>
                <CardContent className={[classes.planCardOutline, classes.planCardOutlineMiddle].join(' ')}>
                  <Typography className={[classes.planTitle, classes.planTitleMiddle].join(' ')}>
                    <PiSparkle className={[classes.sparkleIconMiddle].join(' ')} />
                    Plano Plus
                  </Typography>
                  <Typography className={classes.planHead}>Para Empresas:</Typography>
                  <Typography className={classes.planPrice}>R$ 199,99</Typography>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Acesso completo à nossa plataforma de multi atendimento</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Ferramentas de mensuração e análise</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Suporte dedicado para sua equipe</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Implementação rápida e fácil</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>12 atendentes</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>12 WhatsApp</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>12 Filas</Typography>
                  </div>
                </CardContent>
                <Button size='small' className={[classes.planButton, classes.planButtonMiddle].join(' ')} onClick={() => handleSelectPlan({ id: 4, name: 'Plano Plus', value: 199.99 })}>
                  Começar agora
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.plansCardContainer}>
                <CardContent className={classes.planCardOutline}>
                  <Typography className={classes.planTitle}>
                    <PiSparkle className={classes.sparkleIcon} />
                    Plano Premium
                  </Typography>
                  <Typography className={classes.planHead}>Para Empresas:</Typography>
                  <Typography className={classes.planPrice}>R$ 299,99</Typography>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Acesso completo à nossa plataforma de multi atendimento</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Ferramentas de mensuração e análise</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Suporte dedicado para sua equipe</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>Implementação rápida e fácil</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>15 atendentes</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>15 WhatsApp</Typography>
                  </div>
                  <div className={classes.divFeature}>
                    <IoCheckmark className={classes.checkIcon} />
                    <Typography className={classes.planFeature}>20 Filas</Typography>
                  </div>
                </CardContent>
                <Button size='small' className={classes.planButton} onClick={() => handleSelectPlan({ id: 7, name: 'Plano Premium', value: 299.99 })}>
                  Começar agora
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <Container component='main' maxWidth='xs'>
          <Typography className={classes.subtitleForm}>
            Agora que escolheu o melhor plano para sua empresa, falta apenas <strong>um passo</strong> para acessar seu painel de gerenciamento em nossa ferramenta.
          </Typography>
          <Card className={classes.container}>
            <CardContent>
              <img src={logo} alt='Logo' className={classes.logo} />
              <Formik
                initialValues={{ ...user, planId: selectedPlan.id }}
                enableReinitialize={true}
                validationSchema={UserSchema}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    handleSignUp(values);
                    actions.setSubmitting(false);
                  }, 400);
                }}
              >
                {({ touched, errors }) => (
                  <Form className={classes.form}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          autoComplete='name'
                          name='name'
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          variant='outlined'
                          fullWidth
                          id='name'
                          label='Nome da Empresa'
                          className={classes.textField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start' className={classes.inputAdornment}>
                                <span role="img" aria-label="company">&#128100;</span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          variant='outlined'
                          fullWidth
                          id='email'
                          label='E-mail'
                          name='email'
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          autoComplete='email'
                          required
                          className={classes.textField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start' className={classes.inputAdornment}>
                                <span role="img" aria-label="email">&#128231;</span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field as={InputMask} mask='(99) 99999-9999' variant='outlined' fullWidth id='phone' name='phone' error={touched.phone && Boolean(errors.phone)} helperText={touched.phone && errors.phone} autoComplete='phone' required>
                          {({ field }) => (
                            <TextField
                              {...field}
                              variant='outlined'
                              fullWidth
                              label='Telefone com (DDD)'
                              className={classes.textField}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start' className={classes.inputAdornment}>
                                    <span role="img" aria-label="phone">&#128222;</span>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          variant='outlined'
                          fullWidth
                          name='password'
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          label={i18n.t('signup.form.password')}
                          type={showPassword ? 'text' : 'password'}
                          id='password'
                          autoComplete='current-password'
                          required
                          className={classes.textField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start' className={classes.inputAdornment}>
                                <span role="img" aria-label="password">&#128274;</span>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          variant='outlined'
                          fullWidth
                          name='confirmPassword'
                          error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          label='Confirmar Senha'
                          type={showConfirmPassword ? 'text' : 'password'}
                          id='confirmPassword'
                          autoComplete='current-password'
                          required
                          className={classes.textField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start' className={classes.inputAdornment}>
                                <span role="img" aria-label="confirm-password">&#128274;</span>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowConfirmPassword}
                                >
                                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Button type='submit' fullWidth variant='contained' className={classes.submit}>
                      {i18n.t('signup.buttons.submit')}
                    </Button>
                    <Grid container justify='flex-end'>
                      <Grid item>
                        <Link href='#' variant='body2' onClick={() => setSelectedPlan(null)}>
                          Voltar para a seleção de planos
                        </Link>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
          <Box mt={5}></Box>
        </Container>
      )}
    </Container>
  );
};

export default SignUp;
